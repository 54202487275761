@font-face {
	font-family: 'Gotham';
	src: url(/public/assets/font/Gotham-Book.otf);
	font-weight: 325;
}

@font-face {
	font-family: 'Gotham';
	src: url(/public/assets/font/Gotham-Bold.otf);
	font-weight: 400;
}

body {
	font-family: 'Gotham' !important;
	font-weight: 325 !important;
	color: #53565A !important;
	font-size: 18px !important;
	line-height: 120% !important;
	margin: 0 !important;
	padding: 0 !important;
}

.row {
	margin: 0 -15px;
}

.row>* {
	padding: 0 15px;
}

ul,
ol {
	margin: 0;
}

sup{
    font-size: 1em;
    position: relative;
    top: -0.2em;
}

ul.list-style-none {
	list-style: none;
	padding-left: 0;
}

.show_mobile {
	display: none;
}

.show_desktop {
	display: block;
}

.single-word {
	display: inline-block;
}

.container {
	max-width: 1270px !important;
	width: 100% !important;
	margin: auto;
	padding: 0 15px;
}

.container-copay {
	max-width: 1270px !important;
	width: 100% !important;
	margin: auto;
	padding: 0 15px;
}

/* header*/
header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 1;
}

header .logo {
	margin-top: 32px;
}

.need-help-ampyra-section {
	position: absolute;
	right: 0;
	top: 20px;
	text-align: right;
}

.need-help-ampyra-section button {
	mix-blend-mode: normal;
	box-shadow: 0px 0px 10px rgba(4, 27, 9, 0.2);
	border-radius: 50px;
	border: none;
	padding: 10px 20px 10px 30px;
	border-radius: 50px 0 0 50px;
	font-size: 16px;
	line-height: 20px;
	color: #BB5B16;
	text-align: left;
	background: #fff;
}

.need-help-ampyra-section button div:first-child {
	letter-spacing: 0.02em;
	color: #367C2B;
}

.need-help-ampyra-section button div:first-child a {
	letter-spacing: 0.02em;
	color: #BB5B16;
}

.need-help-ampyra-section button div:first-child a:hover {
	color: #BB5B16;
}

.need-help-ampyra-section button div:last-child {
	font-size: 13px;
	line-height: 15px;
	font-weight: 325;
	letter-spacing: 0.03em;
}

.need-help-ampyra-section img {
	width: 206px;
	margin-right: 30px;
	margin-top: 24px;
}

.welcome-page header .need-help-ampyra-section img,.welcome-page header .logo-header .logo-1 img,.welcome-page header .logo svg{
	filter: brightness(0) invert(1);
}


/*home-banner-section*/
.home-banner-section {
	/* padding: 32px 0 177px; */
	padding: 0;
	background: url(/public/assets/images/MicrosoftTeams-image.webp) no-repeat center center;
	background-size: cover;
	position: relative;
}

.home-banner-section img {
	width: 100%;
	opacity: 0;
	/* max-height: 627px;
	height: 100%; */
}
.home-banner-section .intro-section {
	max-width: 695px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.home-banner-section .intro-section h1 {
	font-weight: 400;
	font-size: 44px;
	line-height: 110%;
	color: #367C2B;
	margin-bottom: 15px;
	letter-spacing: -0.02em;
}

.home-banner-section .intro-section .h3 {
	font-size: 34px;
	line-height: 120%;
	color: #E36F1E;
	max-width: 610px;
	width: 100%;
	margin-bottom: 15px;
}

.home-banner-section .intro-section p {
	font-size: 22px;
	line-height: 160%;
	color: #000000;
	max-width: 610px;
	width: 100%;
	margin-bottom: 0;
}

.home-banner-section .intro-section p span {
	color: #E36F1E;
	font-weight: 400;
}

.home-banner-section .intro-section p span span {
	color: #367C2B;
}

.home-banner-section .access-code-section {
	padding: 24px 0 13px;
	position: absolute;
	bottom: 0;
	width: 100%;
}
.home-banner-section .access-code-section.sticky{
	position: fixed;
    top: 0px;
    background: #367C2B;
    bottom: auto;
    z-index: 9;
    opacity: 100%;
}
.home-banner-section .access-code-section.sticky::after{
	display: none;
}
.home-banner-section .access-code-section .need-help-ampyra-section{
	display: none;
}
.home-banner-section .access-code-section.sticky .need-help-ampyra-section{
	display: block;
}
.home-banner-section .access-code-section.sticky .need-help-ampyra-section button div:last-child{
	text-align: center;
}

.home-banner-section .access-code-section .actor_portrayal{
	position: absolute;
	right: 15px;
	top: -30px;
	font-weight: 325;
	color: #000000;
	text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.home-banner-section .access-code-section::after {
	background: #27541F;
	mix-blend-mode: multiply;
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
}

.home-banner-section .access-code-section .container {
	position: relative;
	z-index: 1;
}

.home-banner-section .access-code-section .logo {
	position: absolute;
	left: 15px;
}

/* .home-banner-section .access-code-section .container{
	display: flex;
	align-items: center;
	justify-content: space-between;
} */
.home-banner-section .access-code-section .form_section {
	max-width: 525px;
	width: 100%;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
}

.home-banner-section .access-code-section .form_section .form_label {
	font-size: 17px;
	line-height: 100%;
	color: #FFFFFF;
	max-width: 230px;
	width: 100%;
	font-weight: 400;
}

.home-banner-section .access-code-section .form_section .form_input_submit {
	max-width: calc(100% - 250px);
	width: 100%;
	display: flex;
    align-items: flex-start;
    gap: 7px;
}
.home-banner-section .access-code-section .form_section .form_input_submit .field_text{
	max-width: calc(100% - 35px - 7px);
    width: 100%;
}
.home-banner-section .access-code-section .form_section .form_input_submit input {
	padding: 9px 20px;
	background: #fff;
	border: none;
	border-radius: 100px;
	font-weight: 325;
	font-size: 14px;
	line-height: 100%;
	width: 100%;
}

.home-banner-section .access-code-section .form_section .form_input_submit input::-webkit-input-placeholder {
	font-size: 14px;
	font-weight: 325;
	line-height: 100%;
}

.home-banner-section .access-code-section .form_section .form_input_submit input:-ms-input-placeholder {
	font-weight: 325;
	font-size: 14px;
	line-height: 100%;
}

.home-banner-section .access-code-section .form_section .form_input_submit input::placeholder {
	font-weight: 325;
	font-size: 14px;
	line-height: 100%;
}

.home-banner-section .access-code-section .form_section .form_input_submit button {
	background: none;
	border: none;
	width: 35px;
    height: 35px;
    padding: 0;
}

.home-banner-section .access-code-section .form_section .form_input_submit button.active svg rect {
	fill: #FFFFFF;
}

.home-banner-section .access-code-section .form_section .form_input_submit button.active svg path {
	fill: #E36F1E
}
.home-banner-section .access-code-section .form_section .form_input_submit .where_to_find{
	text-align: center;

}
.home-banner-section .access-code-section .form_section .form_input_submit .where_to_find a{
	color: rgba(255, 255, 255, 0.8);
	font-weight: 325;
	font-size: 14px;
	line-height: 20px;
}

/* innre page banner*/
.inner-banner-section{
	background: url(/public/assets/images/inner-banner-bg.webp) no-repeat center center;
	position: relative;
	background-size: cover;
}

.inner-banner-section .contact-us-img{
	width: 100%;
	opacity: 0;
}

.inner-banner-section .intro-section{
	position: absolute;
	bottom: 0;
}
.inner-banner-section h1{
	/* padding: 228px 0 58px; */
	padding: 0px 0 58px;
	font-weight: 400;
	font-size: 44px;
	line-height: 110%;
	margin-bottom: 0;
	color: #367C2B;
}
.inner-banner-section .actor_portrayal_vector{
	position: absolute;
	bottom: -9%;
}
.inner-banner-section .actor_portrayal_vector .actor_portrayal{
	color: #000;
	margin-bottom: 10px;
	text-align: right;
	margin-right: 15px;
}

/*my-ampyra-provides-section*/
.my-ampyra-provides-section {
	padding: 80px 0 0;
	background: #fff url('/public/assets/images/ampyra_provides_bg.webp') bottom center no-repeat;
	background-size: contain;
}
.my-ampyra-provides-section .img-section{
	opacity: 0;
	width: 100%;
	position: absolute;
}

.my-ampyra-provides-section h2 {
	font-size: 34px;
	line-height: 120%;
	color: #E36F1E;
	margin-bottom: 27px;
}

.my-ampyra-provides-section h2 span {
	color: #367C2B;
}

.my-ampyra-provides-section .my-ampyra-provides-row {
	margin-left: 0;
	margin-bottom: 20px;
	padding: 20px 50px;
	position: relative;
}

.my-ampyra-provides-section .my-ampyra-provides-row::after {
	content: '';
	/* background: rgba(182, 207, 208, 0.3); */
	max-width: 1060px;
	position: absolute;
	left: 0;
	border-radius: 0 100px 100px 0;
	height: 100%;
	width: 100%;
	top: 0;
	opacity: 90%;
    z-index: 0;
    background: #E6ECEC;
}

.my-ampyra-provides-section .my-ampyra-provides-row .container {
	display: flex;
	gap: 30px;
	position: relative;
	z-index: 1;
}

.my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items {
	display: flex;
	column-gap: 20px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items:first-child {
	max-width: 420px;
}
.my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items img{
	max-width: 100px;
    max-height: 100px;
    height: 100%;
    width: 100%;
}
.my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items:last-child {
	max-width: 430px;
}

.my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items svg {
	width: 100px;
}

.my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items p {
	width: calc(100% - 120px);
	font-weight: 400;
	font-size: 20px;
	line-height: 110%;
	color: #367C2B;
	margin-bottom: 0;
}

.my-ampyra-provides-section .actor_portrayal_vector {
	position: relative;
	margin-top: 187px;
}

.my-ampyra-provides-section .actor_portrayal_vector .actor_portrayal {
	font-size: 13px;
	line-height: 120%;
	color: #000;
	text-align: right;
	margin-bottom: 15px;
	margin-right: 15px;
}

.my-ampyra-provides-section .actor_portrayal_vector .actor_portrayal span{
background: url('/public/assets/images/Ellipse 22-1.webp') center center no-repeat;
    /* background-size: cover;
	border-bottom-left-radius: 50% 25%;
	border-bottom-right-radius: 50% 25%;
	border-top-left-radius: 50% 25%;
	border-top-right-radius: 50% 25%; */
    /* background-size: 113% 155%; */
	background-size: cover;
    padding: 11px;
	padding-left: 20px;
	padding-right: 30px;
}

/*healthcare-provider-tools*/
.healthcare-provider-tools {
	padding: 21px 0 34px;
	/* border-bottom: .5px solid #53565A; */
}

.healthcare-provider-tools .intro-section {
	max-width: 1030px;
	width: 100%;
}

.healthcare-provider-tools .intro-section h4 {
	font-weight: 400;
	font-size: 30.7111px;
	line-height: 120%;
	color: #E36F1E;
	margin-bottom: 20px;
}

.healthcare-provider-tools .intro-section p {
	font-weight: 325;
	color: #515151;
	margin-bottom: 20px;
}

/* healthcare provider? */

.healthcare-provider{
display: flex;
justify-content: center;
border-bottom: 0.5px solid #53565A;
padding-bottom: 144px;
}

.healthcare-provider-background{
	background: url('/public/assets/images/heathcare-background.webp') center center no-repeat;
	background-size: cover;
	max-width: 1181px;
	width: 100%;
	padding: 0px 60px 30px 110px;
	border-radius: 20px;
	color: #fff;
	max-height: 300px;
	height: 100%;
	position: relative;
}

.healthcare-provider-background .content{
	display: flex;
	justify-content: space-between;
    position: relative;
    top: 88px;
}
.healthcare-provider-background a{
	padding:15px 50px;
	background-color: #5E9655;
	color: #fff;
	border: none;
    border-radius: 90.3268px;
    display: inline-block;
	text-decoration: none !important;
}

.healthcare-provider .tab-img{
	max-width: 400px;
	width: 100%;
}

/*coverage-copay section */

.coverage-copay-section {
	padding: 89px 0 0px;
	background: #fff url('/public/assets/images/coverage-copay-bg-image.webp') bottom center no-repeat;
	background-size: contain;
}

.coverage-copay-section .coverage-image{
	width: 100%;
	opacity: 0;
	max-height: 530px;
}


/* .coverage-copay-section:before{
	content: '';
	position: absolute;
	bottom: 0;
	background: url(/public/assets/images/coverage-copay-lower-bg.webp) no-repeat right bottom;
	width: 100%;
	height: 100%;
	background-size: contain;
} */
.coverage-copay-section .intro-section {
	margin-bottom: 38px;
}

.coverage-copay-section .icon-text-section .icon-text-section-item img{
	max-width: 90px;
    max-height: 90px;
    height: 100%;
    width: 100%;
	margin: auto;
}

.coverage-copay-section .intro-section h3 {
	font-size: 34px;
	line-height: 120%;
	color: #E36F1E;
	margin-bottom: 27px;
}

.coverage-copay-section .intro-section p {
	margin-bottom: 15px;
	color: #53565A;
}

.coverage-copay-section .icon-text-section {
	max-width: 1000px;
	margin: auto;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	margin-bottom: 64px;
}

.coverage-copay-section .icon-text-section .icon-text-section-item {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	max-width: 460px;
	width: 100%;
}

.coverage-copay-section .icon-text-section .icon-text-section-sep {
	max-width: calc(100% - 460px * 2);
	display: block;
	width: 100%;
	transform: rotate(90deg);
	margin: auto;
	text-align: center;
	border-color: #53565A;
}

.coverage-copay-section .icon-text-section .icon-text-section-item svg {
	width: 90px;
	height: 90px;
	padding-left: 10px;
	margin-right: 25px;
}

.coverage-copay-section .icon-text-section .icon-text-section-item p {
	max-width: calc(100% - 90px - 25px);
	margin-bottom: 0;
}

.coverage-copay-section .notes {
	margin-bottom: 44px;
}

.coverage-copay-section .notes p {
	font-size: 13px;
	line-height: 120%;
	display: flex;
}

.coverage-copay-section .copay-assistance-available-section {
	max-width: 1120px;
	margin: auto;
	width: 100%;
	padding: 55px 104px 61px;
	background: linear-gradient(94.36deg, #FF7D00 -10.05%, #D86413 70.23%);
	border-radius: 40px;
	text-align: center;
	margin-bottom: 64px;
}

.coverage-copay-section .copay-assistance-available-section h4 {
	font-size: 34px;
	color: #fff;
	margin-bottom: 27px;
}

.coverage-copay-section .copay-assistance-available-section h5 {
	color: #FFFFFF;
	margin-bottom: 15px;
	font-weight: 400;
	font-size: 18px;
}

.coverage-copay-section .copay-assistance-available-section p {
	font-size: 13px;
	line-height: 120%;
	color: #fff;
	text-align: left;
	margin-bottom: 0;
	padding-top: 15px;
	display: flex;
}

.coverage-copay-section .copay-assistance-available-section .zero-dollar-section {
	max-width: 680px;
	width: 100%;
	margin: 50px auto;
	position: relative;

}

.coverage-copay-section .copay-assistance-available-section .zero-dollar-section button {
	background: rgba(238, 238, 238, 0.2);
	box-shadow: 0px 0px 15px rgba(167, 76, 12, 0.6);
	border-radius: 90.3268px;
	width: 100%;
	display: block;
	padding: 27px 0;
	border: none;
	font-size: 25.87px;
	line-height: 90%;
	color: #fff;
	height: 77px;
	cursor: auto !important;
}

.coverage-copay-section .copay-assistance-available-section .zero-dollar-section button span{
	margin-left: 10px;
}

.coverage-copay-section .copay-assistance-available-section .zero-dollar-section button div {
	position: absolute;
	left: 0;
	right: 0;
	top: -20px;
}

.coverage-copay-section .call-us-section {
	max-width: 825px;
	margin: auto;
	width: 100%;
	text-align: center;
}

.coverage-copay-section .call-us-section h5 {
	font-size: 34px;
	line-height: 120%;
	text-align: center;
	color: #53565A;
	font-weight: 325;
	margin-bottom: 24px;
}

.coverage-copay-section .call-us-section button {
	width: 100%;
	padding: 36px 0;
	text-align: center;
	background: rgba(182, 207, 208, 0.3);
	border-radius: 90.3268px;
	margin-bottom: 24px;
	color: #367C2B;
	font-size: 34px;
	line-height: 120%;
	border: none;
}

.coverage-copay-section .call-us-section button a {
	color: #367C2B;
}

.coverage-copay-section .call-us-section p {
	font-size: 18px;
	line-height: 120%;
}

.coverage-copay-section .call-us-section p small {
	font-size: 14px;
}

.coverage-copay-section .actor_portrayal_vector {
	position: relative;
	margin-top: -12%;
}

.coverage-copay-section .actor_portrayal_vector .actor_portrayal {
	position: absolute;
	right: 10px;
	top: 35%;
	;
	font-size: 13px;
	line-height: 120%;
}

/* contact page*/
.contact-section{
	padding: 68px 0 75px;
}
.contact-section p b{
text-decoration: underline !important;
}
.contact-section.bg-color{
	background: #f6f6f6;
}
.contact-section.last-section{
	padding: 68px 0 112px;
	border-bottom: 1px solid #53565A;
}
.contact-section.error-section{
	padding-bottom: 103px;
	border-bottom: 1px solid #53565A;
}

.contact-section h3{
	font-weight: 400;
	font-size: 34px;
	line-height: 120%;
	color: #E36F1E;
	margin-bottom: 27px;
	gap: 10px;
}
.contact-section a{
	color: #53565A;
	text-decoration: underline !important;
	white-space: nowrap;
}
.contact-section a:hover{
	color: #53565A;
}
.contact-section .icon-text-container{
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-wrap: wrap;
	margin-top: 40px;
}
.contact-section .icon-text-container .icon-text-item{
	max-width: 400px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 25px;
}

.contact-section .icon-text-container .icon-text-item img{
	max-width: 90px;
	max-height: 90px;
	width: 100%;
	height: 100%;
}
.contact-section .icon-text-container .icon-text-item h5{
	font-size: 18px;
	line-height: 120%;
	margin-bottom: 5px;
}
.contact-section .icon-text-container .icon-text-item p{
	margin-bottom: 0px;
}

.contact-section.error-section p{
	max-width: 820px;
	width: 100%;
}
.contact-section.error-section a{
	color: #E36F1E;
	text-decoration: underline;
}
.contact-section.error-section a:hover{
	color: #E36F1E;
}

/* welcome page*/
.welcome-banner-section{
	/* padding: 248px 0 150px;
	background: url(/public/assets/images/memberpage_banner.webp) no-repeat center center;
	background-size: cover;
	position: relative; */
	position: relative;
}

.welcome-banner-section img{
	/* position: relative; */
	max-height: 627px;
	height: 100%;
	width: 100%;
}

.welcome-banner-section .intro-section{
	position: absolute;
    top: 34%;
    /* bottom: 50%; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    flex-wrap: wrap;
}
.welcome-banner-section h1{
	font-size: 44px;
	line-height: 110%;
	color: #FFFFFF;
	text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
	margin-bottom: 38px;
}
.welcome-banner-section h1 span{
	color:#E09C6D
}
.welcome-banner-section h2{
	font-size: 34px;
	line-height: 120%;
	color: #FFFFFF;
	text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
	margin-bottom: 0;
	max-width: 730px;
	width: 100%;
}
.welcome-banner-section .actor_portrayal_vector{
	position: absolute;
	bottom: -4.5%;
}
.welcome-banner-section .actor_portrayal_vector .actor_portrayal{
	text-align: right;
	color: #fff;
	margin-right: 10px;
	margin-bottom: 17px;
}
.tracking-your-progress-section{
	padding: 94px 0 117px;
}
.tracking-your-progress-section .container-copay{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.tracking-your-progress-section .left-section{
	max-width: 715px;
	width: 100%;
}
.tracking-your-progress-section .left-section h2{
	font-size: 34px;
	line-height: 120%;
	color: #E36F1E;
	margin-bottom: 27px;
}
.tracking-your-progress-section .left-section p:last-child{
	margin-bottom: 0;
}
.tracking-your-progress-section .left-section h2 span{
	color: #367C2B;
}
.tracking-your-progress-section .left-section ul{
	margin-bottom: 15px;
	padding-left: 1.5em;
}
.tracking-your-progress-section .right-section{
	background: url(/public/assets/images/start-process-image.webp) no-repeat top center;	
	border-radius: 20px;
	padding: 60px 50px 30px;
	max-width: 471px;
	width: 100%;
	text-align: center;
	background-size: cover;
}
.tracking-your-progress-section .right-section h3{
	color: #fff;
	font-size: 34px;
	line-height: 120%;
	margin-bottom: 30px;
}
.tracking-your-progress-section .right-section a{
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0px 0px 15px rgba(167, 76, 12, 0.6);
	border-radius: 100px;
	color: #fff;
	padding: 11px 50px;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
}
.tracking-your-progress-section .right-section a:hover{
	text-decoration: underline;
}

.keep_a_journal{
	/* background: url(/public/assets/images/keep-a-journal.webp) no-repeat center center; */
	/* background-size:contain ; */
	position: relative;
}
.keep_a_journal .container{
	position: absolute;
	top: 10%;
	right: 0;
	left: 0;
}
.keep_a_journal img{
	margin: auto;
}
.keep_a_journal .actor_portrayal{
	font-size: 13px;
	line-height: 120%;
	color: #000000;
	position: absolute;
    right: 15px;
    bottom: 20%;
}
.keep_a_journal .container .content{
	max-width: 545px;
	width: 100%;
	margin-left: auto;
}
.keep_a_journal .container .content h3{
	font-size: 34px;
	line-height: 120%;
	color: #E36F1E;
	margin-bottom: 27px;
}
.keep_a_journal .container .content p{
	margin-bottom: 10px;
}
.keep_a_journal .container .content ul{
	padding-left: 1.4em;
}
.download-resources-section{
	padding: 12px 0 27px;
	border-bottom: 1px solid #53565A;
}
.download-resources-section h4{ 
	font-weight: 400;
	font-size: 30.7111px;
	line-height: 120%;
	color: #E36F1E;
	margin-bottom: 29px;
}
.download-resources-section p{
	margin-bottom: 76px;
	max-width: 100%;
}



/* resources*/
.resources-items {
	display: flex;
	column-gap: 20px;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: stretch;
}

.resources-items .resources-item {
	min-height: 191px;
	display: flex;
	flex-wrap: wrap;
	padding: 12px 0 12px 29px;
	max-width: calc((100% - 20px) / 2);
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
}

.resources-items .resources-item .img-section {
	width: 104px;
}

.resources-items .resources-item .img-section img {
	box-shadow: 0px 3.61307px 5.41961px rgba(0, 0, 0, 0.2);
}

.resources-items .resources-item .text-section {
	max-width: calc(100% - 144px);
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 90%;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: nowrap;
}

.resources-items .resources-item .text-section h5 {
	font-size: 20px;
	line-height: 110%;
	color: #515151;
	margin-bottom: 5px;
	font-weight: 400;
}

.resources-items .resources-item .text-section p {
	color: #515151;
	margin-bottom: 5px;
}

.resources-items .resources-item .text-section a {
	background: #367C2B;
	border-radius: 90.3268px;
	padding: 15px 29px;
	display: inline-block;
	font-weight: 400;
	color: #fff;
	text-decoration: none;
	line-height: 18px;
}

.resources-items .resources-item .text-section a svg {
	margin-right: 10px;
	position: relative;
	top: -2px;
}

.resources-items .resources-item .text-section a:hover {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #367C2B;
}

/* isi section*/
.isi-section {
	padding: 40px 0;
}

.isi-section h5{
	color: #E36F1E;
	margin-bottom: 20px;
	font-weight: 400;
	font-size: 18px;
	line-height: 120%;
}

.isi-section h6{
	color: #53565A;
	margin-bottom: 20px;
	font-weight: 400;
	font-size: 18px;
	line-height: 21.6px;
}

.isi-section h6 span{
	color: #000;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 120%;
	font-weight: 300;
}

.isi-section p,
.isi-section ul {
	color: #000;
	margin-bottom: 20px;
	font-size: 18px;
	line-height: 120%;
	margin-bottom: 15px;
}

.isi-section ul li{
	margin-bottom: 5px;
}

.isi-section ul li span{
	white-space:nowrap;
}

.isi-section ul {
	padding-left: 2em;
}

.isi-section p a {
	color: #000;
}
.isi-section .margin-5{
	margin-bottom: 5px ;
}

/* footer css */

footer {
	padding: 30px 0;
	background: #EEEEEE;
}

footer a {
	font-weight: 400 !important;
	color: #E36F1E !important;
	text-decoration: none !important;
}

footer a:hover {
	text-decoration: underline;
	color: #E36F1E;
}

body #ot-sdk-btn.ot-sdk-show-settings {
	font-weight: 400 !important;
	color: #E36F1E !important;
	text-decoration: none !important;
	font-size: 18px !important;
}

footer a .cookie-notice:hover {
	text-decoration: underline;
	color: #E36F1E;
}

footer .logo {
	margin-bottom: 30px;
}

footer .patient-webite-phone-number-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 50px;
}

footer .patient-webite-phone-number-section li {
	padding-bottom: 20px;
}

footer .patient-webite-phone-number-section li:last-child {
	padding-bottom: 0 !important;
}

footer .phone-number-section li {
	text-align: right;
}

footer .phone-number-section li span {
	font-size: 18px;
	font-weight: 400;
	color: #367C2B;
	line-height: 20px;
	letter-spacing: .02em;
}

footer .phone-number-section li a {
	font-size: 22px;
	line-height: 20px;
	text-decoration: underline;
	letter-spacing: .02em;
}

footer .phone-number-section li a:hover svg circle {
	fill: #367C2B
}

footer .footer-links ul {
	margin-bottom: 23px;
	display: flex;
	justify-content: flex-start;
	column-gap: calc(calc(100% - 980px) / 5);
}

footer .footer-links ul li {
	padding: 0 0 0 0;
	position: relative;
}

footer .footer-links ul li:after {
	content: '|';
	position: absolute;
	color: #53565A;
	padding: 0 24px;
}

footer .footer-links ul li:first-child {
	padding-left: 0;
}

footer .footer-links ul li:last-child {
	padding-right: 0;
}

footer .footer-links ul li:last-child:after {
	display: none;
}

footer hr {
	margin: 30px 0;
	border-top: 1px solid #53565A;
}

footer .footer-notes {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

footer .footer-notes p {
	font-size: 13px;
	line-height: 110%;
	margin-bottom: 0;
}

footer .footer-notes .left-side {
	max-width: 1024px;
	width: 100%;
}

footer .footer-notes .right-side {
	width: 115px;
}

footer .footer-notes .right-side p {
	font-size: 13px;
	line-height: 16px;
	text-align: right;
	letter-spacing: 0.01em;
	color: #53565A;
	width: 115px;
}

/* isi stciky*/
.isi-sticky{
	padding: 17px 0;
	position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
	z-index: 5;
	box-shadow:0px -2px 10px rgba(177, 177, 177, 0.3);
}
.isi-sticky .container{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
}
.isi-sticky h6{
	font-size: 18px;
	line-height: 24px;
	color: #E36F1E;
	margin-bottom: 6px;
}
.isi-sticky p{
	font-size: 16px;
	line-height: 120%;
	margin-bottom: 0;
}

.isi-sticky p span{
	white-space:nowrap;
}

.isi-sticky .left-section,.isi-sticky .right-section{
	/* max-width: 555px; */
	width: 100%;
	padding-right: 15px;
	max-width: calc(50% - 49px );
	width: 100%;
}
.isi-sticky .scroll-button-section{
	width: 65px;
	text-align: center;
}
.isi-sticky .scroll-button-section span{
	font-weight: 325;
	font-size: 12px;
	line-height: 14px;
	color: #E36F1E;
}
.isi-sticky .scroll-button-section svg{
	margin: auto;
}
.isi-sticky #isi-scroll-to-section{
	cursor: pointer;
}

/* modals */
.ampyra-modal .modal-content{
	padding: 40px;
	background: #EEEEEE;
	color: #515151;
	border-radius: 20px;
	border: none;
}
.ampyra-modal .modal-dialog{
	max-width: 600px;
	width: 100%;
}
.ampyra-modal .modal-content .modal-body{
	padding: 0;
}
.ampyra-modal .modal-content .close-button{
	background: none;
	padding: 0;
	border: none;
	position: absolute;
	top: -30px;
	right: -30px;
}
.ampyra-modal .modal-content h5{
	font-size: 20px;
	line-height: 110%;
	color: #E36F1E;
	font-weight: 400;
	margin-bottom: 30px;
}
.ampyra-modal .modal-content h5 span{
	color: #367C2B;
}
.ampyra-modal .modal-content p:last-child{
	margin-bottom: 0;
}
.ampyra-modal .modal-content .button_container{
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.ampyra-modal .modal-content .button_container .button{
	padding: 9px 30px;
	border: 2px solid #367C2B;
	border-radius: 100px;
	font-weight: 400;
	font-size: 18px;
	line-height: 120%;
	text-align: center;
	color: #367C2B;
	text-decoration: none;
}
.ampyra-modal .modal-content .button_container .button:first-child:hover{
	color: #335626;
	border-color: #335626;
}
.ampyra-modal .modal-content .button_container .button:last-child{
	background: #367C2B;
	color: #fff;
}
.ampyra-modal .modal-content .button_container .button:last-child:hover{
	background:linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #367C2B;
	color: #fff;
	border-color: #335626;
}
.ampyra-modal-leaving .modal-content{
	padding: 40px 50px;
}
.ampyra-modal-leaving .modal-content .phone_number{
	margin:25px auto 25px;
	text-align: center;
}

.ampyra-modal-leaving .modal-content .not-ampyra-patient{
	margin-top: 30px;
}

.ampyra-modal-leaving .modal-content .not-ampyra-patient span{
	font-weight: 700;
	text-decoration: underline;
	color: #000 !important;
	text-decoration-color: #000;
}
.ampyra-modal-leaving .modal-content .phone_number a{
	font-weight: 400;
	font-size: 34px;
	line-height: 120%;
	text-align: center;
	text-decoration-line: underline;
	color: #E36F1E;
	display: inline-block;
}
.ampyra-modal-leaving .modal-content .phone_number a:hover{
	text-decoration: none;
	color: #E36F1E;
}
.ampyra-modal-leaving .modal-content p.notes{
	font-size: 13px;
line-height: 120%;
margin-bottom: 0;
text-align: center;
}
.ampyra-modal-leaving .modal-content .button_container{
	justify-content: center;
	margin-top: 25px;
}
.ampyra-modal-email-access .modal-content p{
	margin-bottom: 10px;
}
.ampyra-modal-email-access .modal-content ul{
	padding-left: 1.4em;
}
.ampyra-modal-email-access .modal-content li::marker{
	color: #E36F1E;
}
.ampyra-modal-email-access .modal-content p.notes{
	margin: 25px auto;
	text-align: center;
	font-size: 13px;
	line-height: 120%;
}
.ampyra-modal-email-access .modal-content p.notes a{
	color: #515151;
	font-weight: 400;
/*	text-decoration: none;*/
}
.ampyra-modal-email-access .modal-content p.notes a:hover{
	color: #515151;
	text-decoration: underline;
}
.ampyra-modal-email-access .modal-content input{
	background: #FFFFFF;
	mix-blend-mode: normal;
	border-radius: 100px;
	padding: 15px 20px;
	max-width: 389px;
	font-weight: 325;
	font-size: 14px;
	line-height: 100%;
	border-color: transparent;
	width: 100%;
}
.ampyra-modal-email-access .modal-content .acces-code-email-field-error{
	text-align: center;
	margin: 15px 0 25px;
}
.ampyra-modal-email-access .modal-content input#updateEmailAddress{
}
.ampyra-modal-email-access .modal-content input::-webkit-input-placeholder {
	color: rgba(83, 86, 90, .3);
}

.ampyra-modal-email-access .modal-content input:-ms-input-placeholder {
	color: rgba(83, 86, 90, .3);
}
.ampyra-modal-email-access .modal-content .acces-code-email-field-error #UpdatesendEmailError,.ampyra-modal-email-access .modal-content .acces-code-email-field-error #addEmailAddressError
{
	max-width:389px;
	text-align: left;
	font-size: 13px;
	line-height: 120%;
	color: #780000;
	margin: auto;
	margin-top: 8px;
}
.ampyra-modal-email-access .modal-content input.error{
	color: #780000;
}
.ampyra-modal-email-access .modal-content input.error::-webkit-input-placeholder{
	color: #780000;
	opacity: .5;
}
.ampyra-modal-email-access .modal-content input.error:-ms-input-placeholder{
	color: #780000;
	opacity: .5;
}
.ampyra-modal-email-access .modal-content input.error::placeholder{
	color: #780000;
	opacity: .5;
}

.ampyra-modal-email-access .modal-content .submit_button{
	cursor: pointer;
}
.ampyra-modal-email-access .modal-content .submit_button.disabled{
	pointer-events: none;
	cursor: default;
	opacity: 0.6;
}

.ampyra-modal-email-access .modal-content input::placeholder {
	color: rgba(83, 86, 90, .3);
}
.ampyra-modal-walking-assessment .modal-dialog{
	max-width: 1080px;
	width: 100%;
}
.ampyra-modal-walking-assessment .modal-content{
	padding: 0;
}
.ampyra-modal-walking-assessment .modal-content .close-button{
	top: 40px;
	right: 8px
}
.ampyra-modal-walking-assessment.fix-close .modal-content .close-button{
	top: 8px !important;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps{
	padding: 38px 50px 30px;
	background: url(/public/assets/images/assessment-bg.webp) no-repeat top left;
	background-size: contain;
	border-radius: 20px 20px 0 0;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps h4{
	font-size: 20px;
	line-height: 110%;
	margin-bottom: 100px;
	color: #fff;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .question-details h5{
	font-size: 34px;
	line-height: 120%;
	color: #515151;
	text-align: center;
	max-width: 95%;
	width: 100%;
	margin: auto;
	margin-bottom: 40px;
	display: flex;
	gap: 5px;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .question-details h5 span{
	color: #515151;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options{
	display: flex;
	align-items: center;
	justify-content: space-between;
	accent-color: #D86413;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options .question-option{
	text-align: center;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options input{
	transform: scale(1.5);
	display: block;
	margin: auto;
	margin-bottom: 14px;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options label{
	font-weight: 325;
	text-transform: uppercase;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options .form-control:focus-within {
	color: var(--form-control-color);
  }
.ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container button.hidden{
	visibility: hidden !important;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container .page-bullets{
	display: flex;	
	gap: 20px;
	align-items: center;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container .page-bullets div{
	height: 10px;
	width: 10px;
	background: rgba(227, 111, 30, 0.3);
	border-radius: 100%;
}
.ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container .page-bullets div.active{
	background: rgba(227, 111, 30, 1);
}

.ampyra-modal-walking-assessment .modal-content .other-steps{
	padding: 60px 50px 30px;
	background: url(/public/assets/images/assessment-thank-you-bg.webp) no-repeat bottom center;
	background-size: cover;
	border-radius: 20px;
	text-align: center;
}
.ampyra-modal-walking-assessment .modal-content .other-steps h4{
	font-size: 34px;
	line-height: 120%;
	text-align: center;
	color: #E36F1E;
	margin-bottom: 40px;
}
.ampyra-modal-walking-assessment .modal-content .other-steps p{
	color: #515151;
	text-align: center;
}
.ampyra-modal-walking-assessment .modal-content .other-steps p b{
	font-size: 20px;
	line-height: 120%;
	margin-bottom: 10px;
}

.ampyra-modal-walking-assessment .modal-content .other-steps p.some-witdth{
	margin-bottom: 40px;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .button_container_new{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .button_container_new button{
	max-width: 352px;
	width: 100%;
	padding: 20px 40px;
	background: #367C2B;
	border-radius: 100px;
	color: #fff;
	border: none;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .button_container_new button:hover{
	background: #335626;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data{
	max-width: 430px;
	width: 100%;
	margin: auto;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 40px;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data .step7-email-field-input-button{
	max-width: calc(100% - 57px);
	width: 100%;
}

.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data input{
	padding: 17px;
	background: #FFFFFF;
	border-radius: 100px;
	font-size: 14px;
	line-height: 100%;
	border: none;
	width: 100%;
	font-weight: 325;
	height: 50px;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data input.error{
	color: #780000;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data input.error::-webkit-input-placeholder{
	color: #780000;
	opacity: .5;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data input.error:-ms-input-placeholder{
	color: #780000;
	opacity: .5;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data input.error::placeholder{
	color: #780000;
	opacity: .5;
}

.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data button{
	border:none;
	background: none;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data button.active svg rect {
	fill: #FFFFFF;
}

.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data button.active svg path {
	fill: #E36F1E
}
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data button:disabled,
.ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data button:disabled button[disabled]{
	opacity: .5;
	pointer-events: none;
}
.ampyra-modal-walking-assessment .modal-content .err-msg{
	max-width: 430px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}


.ampyra-modal-walking-assessment .modal-content #sendEmailError{
	margin-top: 10px;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 2px;
	padding: 5px;
	font-weight: 325;
	font-size: 13px;
	line-height: 120%;	
	color: #780000;
	max-width: max-content;
	margin-left: 20px;
}
.ampyra-modal-walking-assessment .modal-content .other-steps button.link{
	font-size: 13px;
	line-height: 120%;
	color: #FFFFFF;
	display: block;
	background: none;
	border: none;
	font-weight: 325;
	text-decoration: underline;
	margin: auto;
	margin-top: 40px;
}
.ampyra-modal-walking-assessment .modal-content .other-steps .thankyou-field-for-data h5{
	font-size: 20px;
	line-height: 110%;
	margin-top: 40px;
	color: #fff;
}

.ampyra-modal .modal-content .button_container .button.next{
	background: #367C2B;
	color: #fff;
}
.ampyra-modal .modal-content .button_container .button.next:hover{
	background: #335626;
	border-color: #335626;
}

/* cookie banner style */
/* #ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  color: #68b631;
  border: none !important;
  height: auto;
  padding: 0px !important;
  cursor: pointer;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #FFFFFF !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: block !important;
  font-family: 'Gotham' !important;

}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings:hover {
  border: none !important;
  height: auto;
  background: none !important;
  padding: 0px !important;
  cursor: pointer;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #FFFFFF !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: block !important;
  font-family: 'Gotham' !important;

}

#onetrust-consent-sdk #onetrust-banner-sdk a[href],
#onetrust-consent-sdk #onetrust-banner-sdk a[href] font,
#onetrust-consent-sdk #onetrust-banner-sdk .ot-link-btn {
  text-decoration-skip-ink: none !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 140% !important;
  letter-spacing: -0.01em !important;
  color: #FFFFFF !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
	background-color: #367C2B !important;
	color: #fff !important;
  }
  
  #onetrust-policy-text a {
	font-weight: 700 !important;
  }
  
  #onetrust-banner-sdk .ot-sdk-container {
	max-width: 1340px !important;
  }
  
  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
	margin-left: 14px;
  }
  
  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
	margin-right: 14px !important;
  }
  
  #onetrust-consent-sdk #onetrust-policy-text {
	font-family: 'Gotham' !important;
	font-style: normal !important;
	font-weight: 325 !important;
	font-size: 14px !important;
	line-height: 140% !important;
	letter-spacing: -0.01em !important;
	color: #FFFFFF !important;
	text-align: left !important;
  }
  
  #onetrust-consent-sdk #onetrust-policy-text a {
	font-style: normal !important;
	font-weight: 400 !important;
	font-family: 'Gotham' !important;
	font-size: 14px !important;
	line-height: 140% !important;
	letter-spacing: -0.01em !important;
	color: #FFFFFF !important;
  }
  
  #onetrust-banner-sdk #onetrust-pc-btn-handler.cookie-setting-link {
	background-color: #367C2B !important;
	border: none;
	text-decoration: underline;
	font-family: 'Gotham' !important;
	font-style: normal;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 120% !important;
	text-align: center !important;
	letter-spacing: -0.01em !important;
	text-decoration-line: underline !important;
	background-color: #367C2B !important;
	color: #FFFFFF !important;
	margin-top: -5px !important;
  }

  #onetrust-banner-sdk #onetrust-button-group {
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: end;
	justify-content: flex-end;
	column-gap: 20;
  }
  
  #onetrust-banner-sdk #onetrust-button-group button#onetrust-accept-btn-handler {
	background: #ffffff !important;
	border-radius: 100px !important;
	color: #3e1647 !important;
	border: none !important;
	padding: 12px 12px !important;
  }
  
  
  #onetrust-banner-sdk #onetrust-close-btn-container {
	background: #5e3068 !important;
	border-radius: 100% !important;
	height: 40px !important;
	width: 40px !important;
  }
  
  #onetrust-banner-sdk #onetrust-close-btn-container .banner-close-button {
	left: -2px !important;
	top: -2px !important;
  }
  
  #onetrust-consent-sdk #onetrust-pc-btn-handler,
  #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
	margin-right: 20px !important;
  }
  
  #onetrust-consent-sdk #onetrust-accept-btn-handler,
  #onetrust-banner-sdk #onetrust-reject-all-handler {
	font-family: "Gotham";
	font-style: normal;
	font-weight: 325;
	font-size: 14px;
	line-height: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	border-radius: 100px;
  }
  
  #onetrust-banner-sdk #onetrust-accept-btn-handler {
	background-color: #fff !important;
	color: #000 !important;
	border-radius: 100px !important;
	padding: 20px 32px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 16px !important;
	letter-spacing: -0.02em !important;
	text-transform: uppercase !important;
	color: #3E1647 !important;
	min-width: 0px !important;
	align-items: center !important;
	justify-content: center !important;
	font-family: 'Gotham' !important;
  }
  
  #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group-parent {
	padding-left: 0% !important;
	padding-right: 0% !important;
  }
  
  #onetrust-banner-sdk div {
	background-color: #367C2B !important;
	color: #fff !important;
	overflow: hidden !important;
	text-align: center !important;
  }

  #onetrust-pc-btn-handler{
	max-width: 150px !important;
	width: 100% !important;
  } */

  #ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
	color: #68b631;
	border: none !important;
	height: auto;
	/* white-space: none; */
	/* word-wrap: break-word; */
	padding: 0px !important;
	cursor: pointer;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	color: #FFFFFF !important;
	text-decoration: none !important;
	cursor: pointer !important;
	display: block !important;
	font-family: 'Gotham' !important;

}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings:hover {
	border: none !important;
	height: auto;
	background: none !important;
	/* white-space: none; */
	/* word-wrap: break-word; */
	padding: 0px !important;
	cursor: pointer;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	color: #FFFFFF !important;
	text-decoration: none !important;
	cursor: pointer !important;
	display: block !important;
	font-family: 'Gotham' !important;

}

#onetrust-consent-sdk #onetrust-banner-sdk a[href],
#onetrust-consent-sdk #onetrust-banner-sdk a[href] font,
#onetrust-consent-sdk #onetrust-banner-sdk .ot-link-btn {
	text-decoration-skip-ink: none !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 140% !important;
	letter-spacing: -0.01em !important;
	color: #FFFFFF !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
	background-color: #367C2B !important;
	color: #fff !important;
}

#onetrust-policy-text a {
	font-weight: 700 !important;
}

#onetrust-banner-sdk .ot-sdk-container {
	max-width: 1340px !important;
}

#onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
	margin-left: 14px;
}

#onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
	margin-right: 14px !important;
}

#onetrust-consent-sdk #onetrust-policy-text {
	font-family: 'Gotham' !important;
	font-style: normal !important;
	font-weight: 325 !important;
	font-size: 14px !important;
	line-height: 140% !important;
	letter-spacing: -0.01em !important;
	color: #FFFFFF !important;
	text-align: left !important;
}

#onetrust-consent-sdk #onetrust-policy-text a {
	font-style: normal !important;
	font-weight: 400 !important;
	font-family: 'Gotham' !important;
	font-size: 14px !important;
	line-height: 140% !important;
	letter-spacing: -0.01em !important;
	color: #FFFFFF !important;
}
/* 
#onetrust-banner-sdk #onetrust-pc-btn-handler.cookie-setting-link {
	background-color: #367C2B !important;
	border: none;
	text-decoration: underline;
	font-family: 'Gotham' !important;
	font-style: normal;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 120% !important;
	text-align: center !important;
	letter-spacing: -0.01em !important;
	text-decoration-line: underline !important;
	background-color: #367C2B !important;
	color: #FFFFFF !important;
	margin-top: -5px !important;
} */

#onetrust-banner-sdk #onetrust-pc-btn-handler {
	background-color: #367C2B !important;
	border: none;
	text-decoration: underline;
	font-family: 'Gotham' !important;
	font-style: normal;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 120% !important;
	text-align: center !important;
	letter-spacing: -0.01em !important;
	text-decoration-line: underline !important;
	background-color: #367C2B !important;
	color: #FFFFFF !important;
	margin-top: -5px !important;
}
/* #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
	width: calc(100% - 64% - 40px) !important;
  } */

#onetrust-banner-sdk #onetrust-button-group {
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: end;
	justify-content: flex-end;
	column-gap: 20;
}

#onetrust-banner-sdk #onetrust-button-group button#onetrust-accept-btn-handler {
	background: #ffffff !important;
	border-radius: 100px !important;
	color: #3e1647 !important;
	border: none !important;
	padding: 12px 12px !important;
	width: 30% !important;
}


#onetrust-banner-sdk #onetrust-close-btn-container {
	/* background: #fff !important; */
	border-radius: 100% !important;
	height: 40px !important;
	width: 40px !important;
	/* position: relative; */
}

#onetrust-banner-sdk #onetrust-close-btn-container .banner-close-button {
	left: -2px !important;
	top: -2px !important;
	background-image: none !important;
}

body #onetrust-banner-sdk #onetrust-close-btn-container .banner-close-button {
	background-image: none !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
	margin-right: 20px !important;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
	font-family: "Gotham";
	font-style: normal;
	font-weight: 325;
	font-size: 14px;
	line-height: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	border-radius: 100px;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler {
	background-color: #fff !important;
	color: #000 !important;
	border-radius: 100px !important;
	padding: 20px 32px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 16px !important;
	letter-spacing: -0.02em !important;
	text-transform: uppercase !important;
	color: #3E1647 !important;
	min-width: 0px !important;
	/* display: flex !important;  */
	align-items: center !important;
	justify-content: center !important;
	font-family: 'Gotham' !important;
}

#onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group-parent {
	padding-left: 0% !important;
	padding-right: 0% !important;
}

#onetrust-banner-sdk div {
	background-color: #367C2B !important;
	color: #fff !important;
	overflow: hidden !important;
	text-align: center !important;
}

#onetrust-pc-btn-handler {
	max-width: 150px !important;
	width: 100% !important;
}

#onetrust-banner-sdk .ot-sdk-row, #onetrust-pc-sdk .ot-sdk-row, #ot-sdk-cookie-policy .ot-sdk-row {
    margin: 0 !important;
    max-width: none !important;
    display: flex !important;
}