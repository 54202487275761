@media screen and (min-width:2600px) {
    .inner-banner-section .actor_portrayal_vector {
        position: absolute;
        bottom: -19%;
    }
}

@media screen and (min-width:2240px) {
    .welcome-banner-section .actor_portrayal_vector {
        bottom: -10.5%;
    }
}



@media screen and (min-width:1700px) and (max-width:2599px) {
    .inner-banner-section .actor_portrayal_vector {
        position: absolute;
        /* bottom: -15%; */
    }

    .coverage-copay-section .coverage-image {
        width: 100%;
        opacity: 0;
        /* max-height: 740px; */
    }
}

@media screen and (min-width:1700px) and (max-width:2058px) {
    .coverage-copay-section .coverage-image {
        width: 100%;
        opacity: 0;
        /* max-height: 700px; */
    }

}

@media screen and (min-width:1540px) and (max-width:2239px) {
    .welcome-banner-section .actor_portrayal_vector {
        bottom: -6.5%;
    }
}

@media screen and (min-width:1540px) and (max-width:1800px) {
    .coverage-copay-section .coverage-image {
        width: 100%;
        opacity: 0;
        /* max-height: 600px; */
    }
}

@media screen and (min-width:1540px) {
    .coverage-copay-section .actor_portrayal_vector {
        margin-top: 5% !important;
    }

}

@media screen and (min-width:1440px) and (max-width:1540px) {
    .coverage-copay-section .actor_portrayal_vector {
        margin-top: -1% !important;
    }
}


@media screen and (min-width:1440px) {
    .my-ampyra-provides-section {
        background-size: cover;

    }

    .my-ampyra-provides-section .my-ampyra-provides-row::after {
        max-width: 73.47%;
    }

    .welcome-banner-section img {
        max-height: 100%;
        height: 100%;
    }
}

@media screen and (min-width:1300px) {
    .coverage-copay-section .actor_portrayal_vector {
        margin-top: -5%;
    }
}

@media screen and (min-width:1150px) and (max-width:1300px) {
    .coverage-copay-section .coverage-image {
        width: 100%;
        opacity: 0;
        /* max-height: 440px; */
    }
}

@media screen and (max-width:1250px) {

    /* #onetrust-banner-sdk #onetrust-button-group {
        justify-content: flex-end !important;
    } */
    .contact-section .icon-text-container {
        row-gap: 20px
    }
}

/* @media screen and (max-width:1250px) and (min-width:767px) {
    #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
        width: 50% !important;
    }
    #onetrust-banner-sdk #onetrust-button-group {
        justify-content: flex-start !important;
    }
    #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
        width: 50% !important;
    }

} */

@media screen and (max-width:1150px) and (min-width:767px) {
    .home-banner-section {
        padding: 32px 0 177px;
        /* background:  url(/public/assets/images/homepage-bg-tab.webp) no-repeat center center; */
    }
}

@media screen and (max-width:1200px) {
    footer .footer-links ul li:after {
        padding: 0 10px;
    }

    footer .footer-links ul {
        column-gap: calc(20% - 210px);
    }
}

@media screen and (max-width: 1150px) {
    footer .footer-links ul li:after {
        padding: 0px 3px;
    }

    footer .footer-links ul {
        column-gap: calc(20% - 194px);
    }
}

@media screen and (max-width:1024px) {

    footer .logo {
        max-width: 156px;
    }

    footer a {
        font-size: 15px;
    }

    body #ot-sdk-btn.ot-sdk-show-settings {
        font-size: 15px !important;
    }

    footer .logo svg {
        max-width: 100%;
    }

    footer .patient-webite-phone-number-section {
        display: block;
    }

    footer .patient-webite-phone-number-section .patient-website-section {
        margin-bottom: 36px;
    }

    footer .patient-webite-phone-number-section .phone-number-section li {
        text-align: left;
        padding-bottom: 10px;
    }

    footer .patient-webite-phone-number-section {
        margin-bottom: 40px;
    }

    footer .phone-number-section li span {
        font-size: 15px;
        line-height: 20px;
    }

    footer .phone-number-section li a {
        font-size: 18px;
        line-height: 20px;
    }

    footer .phone-number-section li a svg {
        max-width: 30px;
    }

    footer .footer-links ul {
        flex-direction: column;
        column-gap: 0;
    }

    footer .footer-links ul li {
        margin-bottom: 20px;
    }

    footer .footer-links ul li:last-child {
        margin-bottom: 0;
    }

    footer .footer-links ul li:after {
        display: none;
    }

    footer .footer-notes {
        display: block;
    }

    footer .footer-notes p {
        font-size: 12px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #53565A;
        margin-bottom: 20px;
    }

    footer .footer-notes .right-side {
        margin-left: auto;
        text-align: right;
    }

    footer .footer-notes .right-side p {
        font-size: 12px;
        line-height: 14px;
        margin: 0;
    }

    .inner-banner-section .actor_portrayal_vector .bottom_vector-position {
        position: relative;
        bottom: 0;
        top: -10px;
    }

    .coverage-copay-section .coverage-image {
        width: 100%;
        opacity: 0;
        /* max-height: 380px; */
    }
}

@media screen and (max-width:990px) {
    .welcome-banner-section h1 {
        font-size: 24px;
        line-height: 110%;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
        margin-bottom: 10px;
    }

    .welcome-banner-section h2 {
        font-size: 20px;
        line-height: 110%;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
        max-width: 287px;
    }

    .welcome-banner-section .actor_portrayal_vector .actor_portrayal {
        font-size: 12px;
        line-height: 100%;
    }

    .inner-banner-section .contact-us-img {
        display: none;
    }

    .inner-banner-section .intro-section {
        position: static;
        bottom: 0;
    }

    .inner-banner-section h1 {
        padding: 228px 0 58px;
    }

    .coverage-copay-section .coverage-image {
        width: 100%;
        opacity: 0;
        /* max-height: 340px; */
    }

    .coverage-copay-section .actor_portrayal_vector {
        margin-top: -12% !important;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps {
        padding: 25px 15px 25px;
        background: url(/public/assets/images/assessment-bg-mobile.webp) no-repeat top left;
        background-size: 100% 75px;
    }
}

@media screen and (max-width:880px) {
    .healthcare-provider-background {
        background: url('/public/assets/images/heathcare-background.webp') center center no-repeat;
        background-size: cover;
        max-width: 1181px;
        width: 100%;
        padding: 15px;
        border-radius: 0px;
        color: #fff;
        max-height: 100%;
        min-height: 100%;
        height: 100%;
        position: relative;
        padding-top: 100px;
        margin-top: -100px;
    }

    .healthcare-provider-background .content {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        top: 0px;
        flex-direction: column-reverse;
    }

    .healthcare-provider {
        padding-bottom: 0;
    }

    .healthcare-provider-background .h2 {
        margin-top: 20px;
    }

    .healthcare-provider .content-details {
        text-align: center;
    }

    .healthcare-provider-background a {
        padding: 15px 50px;
        background-color: #5E9655;
        color: #fff;
        border: none;
        border-radius: 90.3268px;
        display: inline-block;
        margin-bottom: 30px;
    }

    .healthcare-provider .tab-img {
        max-width: 153px;
        width: 100%;
    }

    .coverage-copay-section .coverage-image {
        width: 100%;
        opacity: 0;
        /* max-height: 300px; */
    }

    .keep_a_journal img {
        margin-top: -20px;
    }

    .keep_a_journal .container {
        position: static;
        margin-bottom: 30px;
    }

    .keep_a_journal .container .content {
        max-width: 100%;
        position: relative;
        z-index: 2;
    }

    .keep_a_journal .container .content h3 {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 20px;
    }

    .keep_a_journal .container .content p,
    .keep_a_journal .container .content ul {
        font-size: 16px;
        line-height: 110%;
    }

    .keep_a_journal .container .content ul {
        padding-left: 1.4em;
    }

    .keep_a_journal .container .content li {
        margin-bottom: 3px;
    }

    .keep_a_journal .container .content p {
        margin-bottom: 10px;
    }

    .keep_a_journal .actor_portrayal {
        bottom: 18%;
    }

    .keep_a_journal {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 20px;
    }

}

@media screen and (max-width:1024px) and (min-width:990px) {
    .home-banner-section .intro-section h1 {
        font-size: 36px;
        line-height: 110%;
        margin-bottom: 4px;
        max-width: 700px;
    }
}

@media screen and (max-width:1024px) and (min-width:840px) {
    .home-banner-section .intro-section {
        margin-top: 17px;
    }

    .home-banner-section .intro-section h3 {
        font-size: 30px;
        line-height: 110%;
        max-width: 400px;
    }

    .home-banner-section .intro-section p {
        font-size: 17px;
        line-height: 110%;
        max-width: 400px;
    }

    .tracking-your-progress-section .container-copay {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .tracking-your-progress-section .left-section {
        max-width: 1000px;
        width: 100%;
    }

    .tracking-your-progress-section .right-section {
        margin-top: 50px;
    }

}

@media screen and (max-width:840px) and (min-width:767px) {
    /* .home-banner-section .intro-section {
        margin-top: 17px;
    } */

    .home-banner-section .intro-section h1 {
        font-size: 28px;
        line-height: 110%;
        margin-bottom: 4px;
        max-width: 800px;
    }

    .home-banner-section .intro-section h3 {
        font-size: 20px;
        line-height: 110%;
        max-width: 500px;
    }

    .home-banner-section .intro-section p {
        font-size: 17px;
        line-height: 110%;
        max-width: 400px;
    }

    header .logo a svg {
        width: 160px;
    }

    .need-help-ampyra-section img {
        width: 160px;
    }

    .tracking-your-progress-section .container-copay {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .tracking-your-progress-section .left-section {
        max-width: 790px;
        width: 100%;
    }

    .tracking-your-progress-section .right-section {
        margin-top: 50px;
    }

    .contact-section .icon-text-container {
        row-gap: 20px;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .home-banner-section {
        padding: 32px 0 177px;
        background: url(/public/assets/images/home-bg-tab1.webp) no-repeat center center;
        background-size: cover;
        position: relative;
        background-position-x: -135px;
    }

    .inner-banner-section {
        padding-top: 50px;
        background-position-x: -150px;
    }
}

@media (max-width:990px) and (min-width:768px) {

    .home-banner-section .intro-section h1 {
        font-size: 28px;
        line-height: 110%;
        margin-bottom: 4px;
        max-width: 800px;
    }
}

@media (max-width:990px) and (min-width:800px) {
    .inner-banner-section {
        background: url(/public/assets/images/contact-bg-tab.webp) no-repeat center center;
        position: relative;
        background-size: cover;
        background-position-x: -120px;
        padding-top: 0px;

    }
}

@media (max-width:800px) and (min-width:768px) {
    .inner-banner-section {
        background: url(/public/assets/images/contact-bg-tab.webp) no-repeat center center;
        position: relative;
        background-size: cover;
        background-position-x: -123px;
        padding-top: 0px;
    }
}

@media screen and (max-width:767px) {
    .show_mobile {
        display: block;
    }

    .show_desktop {
        display: none !important;
    }

    sup {
        font-size: .7em;
        position: relative;
        top: -0.5em;
    }

    .isi-sticky {
        padding: 10px 0;
        font-family: Arial, Helvetica, sans-serif;
    }

    .isi-sticky .container {
        display: block;
    }

    .isi-sticky .left-section,
    .isi-sticky .right-section {
        max-width: 100%;
        margin-bottom: 8px;
        padding-right: 0;
    }

    .isi-sticky .right-section {
        margin-bottom: 0;
        padding-right: 0;
    }

    .isi-sticky .scroll-button-section {
        font-size: 11px;
        line-height: 13px;
        width: auto;
        display: flex;
        align-items: center;
        gap: 5px;
        position: absolute;
        top: 10px;
        right: 15px;
    }

    .isi-sticky h6 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 2px;
    }

    .isi-sticky p {
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 0;
    }

    header .need-help-ampyra-section {
        /* position: static; */
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
        /* margin: 0px -15px; */
        top: 0px;
    }

    header .need-help-ampyra-section button {
        width: 100%;
        border-radius: 0;
        text-align: center;
        padding: 12px 15px 12px;
        box-shadow: 0px 0px 10px rgba(4, 27, 9, 0.15);
        background: #fff;
    }

    header .need-help-ampyra-section button div:first-child {
        font-size: 14px;
        line-height: 20px;
    }

    header .need-help-ampyra-section button div:first-child a {
        font-size: 16px;
        line-height: 20px;
    }

    header .need-help-ampyra-section button div:last-child {
        font-size: 11px;
        line-height: 11px;
        font-weight: 325;
        letter-spacing: 0.02em;
        margin-top: 2px;
    }

    header .logo-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 330px;
        width: 100%;
        margin: 27px auto 0;
        top: 80px;
        position: relative;
    }

    header .logo {
        margin-top: 0;
        max-width: 158px;
    }

    header .logo svg {
        max-width: 100%;
        height: auto;
    }

    header .logo-1 {
        max-width: 120px;
        height: auto;
    }

    header .logo-sep {
        width: calc(100% - 278px);
        height: 100%;
    }

    header .logo-sep hr {
        text-align: center;
        margin: auto;
        transform: rotate(90deg);
        width: 40px;
        border-color: rgba(83, 86, 90, 0.3);
    }

    .welcome-page header .logo-sep hr {
        border-color: rgba(255, 255, 255, 1)
    }

    /*inner header*/
    .inner-banner-section {
        background: url(/public/assets/images/inner-banner-bg-mobile.webp) no-repeat bottom center;
        position: relative;
        background-size: cover;
    }

    .inner-banner-section h1 {
        padding: 261px 0 35px;
        font-size: 24px;
        margin-bottom: 0;
    }

    .inner-banner-section .actor_portrayal_vector {
        position: absolute;
        bottom: -9%;
    }

    .inner-banner-section .actor_portrayal_vector .actor_portrayal {
        font-size: 12px;
        line-height: 100%;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 3px;
    }

    /*modal*/
    .ampyra-modal .modal-dialog {
        max-width: calc(100% - 30px);
        margin-left: auto;
        margin-right: auto;
    }

    .ampyra-modal .modal-content {
        padding: 50px 20px 30px;
    }

    .ampyra-modal .modal-content .close-button {
        top: -44px;
        right: -14px;
    }

    .ampyra-modal .modal-content .close-button svg {
        height: 35px;
        width: 35px;
    }

    .ampyra-modal .modal-content h5 {
        font-size: 16px;
        line-height: 110%;
        margin-bottom: 20px;
        max-width: 200px;
    }

    .ampyra-modal .modal-content .modal-body p {
        font-size: 16px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .ampyra-modal .modal-content .button_container {
        flex-direction: column;
        margin-top: 20px;
    }

    .ampyra-modal .modal-content .button_container .button {
        padding: 10px 30px;
        font-size: 15px;
        line-height: 110%;
        display: block;
        width: auto;
        margin: auto;
        margin-bottom: 20px;
    }

    .ampyra-modal .modal-content .button_container .button:last-child {
        margin-bottom: 0;
    }

    .home-banner-section {
        padding: 150px 0 262px;
        background: url(/public/assets/images/homepage-banner-bg-mobile.webp) no-repeat center center;
        background-size: cover;
        background-position-y: 20px;
    }

    .home-banner-section .intro-section {
        margin-top: 17px;
    }

    .home-banner-section .intro-section h1 {
        font-size: 24px;
        line-height: 110%;
        margin-bottom: 4px;
        max-width: 339px;
    }

    .home-banner-section .intro-section h3 {
        font-size: 20px;
        line-height: 110%;
        max-width: 202px;
    }

    .home-banner-section .intro-section p {
        font-size: 17px;
        line-height: 110%;
        max-width: 202px;
    }

    .home-banner-section .access-code-section .logo {
        display: none;
    }

    .home-banner-section .access-code-section .need-help-ampyra-section {
        display: none !important;
    }

    .home-banner-section .access-code-section .form_section {
        display: block;
        text-align: center;
    }

    .home-banner-section .access-code-section .form_section .form_label {
        max-width: 100%;
        font-size: 14px;
        margin-bottom: 12px;
    }

    .home-banner-section .access-code-section .form_section .form_input_submit {
        max-width: 302px;
        margin: auto;
    }

    .home-banner-section .access-code-section .form_section .form_input_submit input {
        margin-bottom: 8px;
    }

    .home-banner-section .access-code-section .actor_portrayal {
        font-weight: 325;
        font-size: 12px;
        line-height: 100%;
        color: #FFFFFF;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
        top: -20px;
        left: 15px;
    }

    .my-ampyra-provides-section {
        padding: 43px 0 0;
        background: #fff url('/public/assets/images/ampyra_provides_bg_mobile.webp') bottom center no-repeat;
        background-size: contain;

    }

    .my-ampyra-provides-section h2 {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 29px;
    }

    .my-ampyra-provides-section .my-ampyra-provides-row {
        margin-bottom: 0;
        padding: 0;
    }

    .my-ampyra-provides-section .my-ampyra-provides-row .container {
        display: block;
        margin-left: -15px;
        width: calc(100% + 15px);
        max-width: calc(100% + 15px);
    }

    .my-ampyra-provides-section .my-ampyra-provides-row::after {
        display: none
    }

    .my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items {
        margin-bottom: 15px;
        padding-left: 15px;
        /* background: rgba(182, 207, 208, 0.3); */
        border-radius: 0px 100px 100px 0px;
        padding-right: 20px;
        padding: 5px 15px;
        max-width: 100% !important;
        opacity: 90%;
        background: #E6ECEC;
    }

    .my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items svg {
        width: 50px;
        height: 50px;
    }

    .my-ampyra-provides-section .my-ampyra-provides-row .my-ampyra-provides-items p {
        width: calc(100% - 70px);
        font-size: 15px;
        line-height: 100%;
    }

    .my-ampyra-provides-section .actor_portrayal_vector {
        margin-top: 156px;
    }

    .my-ampyra-provides-section .actor_portrayal_vector .actor_portrayal {
        position: absolute;
        left: 15px;
        top: 40%;
        margin: 0;
        font-size: 12px;
        line-height: 100%;
    }

    .coverage-copay-section {
        padding: 43px 0 0px;
        background: #fff url('/public/assets/images/coverage-copay-mobile-bg.webp') bottom center no-repeat;
        background-size: contain;
    }

    .coverage-copay-section .intro-section {
        margin-bottom: 30px;
    }

    .coverage-copay-section .intro-section h3 {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 20px;
    }

    .coverage-copay-section .intro-section p {
        font-size: 16px;
        line-height: 110%;
    }

    .coverage-copay-section .icon-text-section {
        margin-bottom: 40px;
        display: block;
    }

    .coverage-copay-section .icon-text-section .icon-text-section-item {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .coverage-copay-section .icon-text-section .icon-text-section-item svg {
        width: 50px;
        height: 50px;
        padding-left: 0;
        margin-right: 20px;
    }

    .coverage-copay-section .icon-text-section .icon-text-section-item p {
        font-size: 15px;
        line-height: 120%;
        max-width: calc(100% - 50px - 20px);
    }

    .coverage-copay-section .icon-text-section .icon-text-section-sep {
        display: none;
    }

    .coverage-copay-section .notes {
        margin-bottom: 54px;
    }

    .coverage-copay-section .notes p {
        font-size: 12px;
        line-height: 100%;
    }

    .coverage-copay-section .copay-assistance-available-section {
        padding: 47px 15px;
        border-radius: 0;
        margin: 0 -15px 62px;
        max-width: calc(100% + 30px);
        width: calc(100% + 30px);
    }

    .coverage-copay-section .copay-assistance-available-section h4 {
        font-size: 20px;
        line-height: 110%;
    }

    .coverage-copay-section .copay-assistance-available-section h5 {
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 10px;
    }

    .coverage-copay-section .copay-assistance-available-section p {
        font-size: 12px;
        line-height: 100%;
        padding-top: 20px;
    }

    .coverage-copay-section .copay-assistance-available-section .zero-dollar-section {
        max-width: 228px;
    }

    .coverage-copay-section .copay-assistance-available-section .zero-dollar-section button {
        padding: 10px;
        font-size: 15px;
        line-height: 15px;
        height: 45px;
    }

    .coverage-copay-section .copay-assistance-available-section .zero-dollar-section button div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        top: -15px;
    }

    .coverage-copay-section .copay-assistance-available-section .zero-dollar-section button div svg {
        width: 70px;
        height: 70px;
    }

    .coverage-copay-section .copay-assistance-available-section .zero-dollar-section button div span {
        max-width: 113px;
        width: 100%;
    }

    .coverage-copay-section .call-us-section h5 {
        font-size: 22px;
        line-height: 110%;
        margin-bottom: 20px;
    }

    .coverage-copay-section .call-us-section p {
        font-size: 16px;
        line-height: 110%;
    }

    .coverage-copay-section .call-us-section p small {
        font-size: 12px;
    }

    .coverage-copay-section .call-us-section button {
        width: auto;
        padding: 15px 50px;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 110%;
    }

    .coverage-copay-section .call-us-section button a {
        display: block;
    }

    /* .coverage-copay-section .actor_portrayal_vector {
        margin-top: 173px;
    } */

    .coverage-copay-section .actor_portrayal_vector .actor_portrayal {
        position: static;
        margin-left: 15px;
        color: #000;
        margin-bottom: 10px;
    }

    .healthcare-provider-tools {
        padding: 21px 0 29px;
    }

    .healthcare-provider-tools .intro-section {
        margin-bottom: 0px;
    }

    .healthcare-provider-tools .intro-section h4 {
        font-size: 20px;
        line-height: 110%;
    }

    .healthcare-provider-tools .intro-section p {
        font-size: 16px;
        line-height: 110%;
    }

    .resources-items {
        display: block;
    }

    .resources-items .resources-item {
        min-height: auto;
        padding: 37px 0 37px 18px;
        align-items: flex-start;
        max-width: 100%;
        border-bottom: 1px solid rgba(83, 86, 90, 0.2);
        margin-bottom: 0;
    }

    .resources-items .resources-item:last-child {
        border-bottom: 0;
    }

    .resources-items .resources-item .text-section h5 {
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .resources-items .resources-item .text-section p {
        font-size: 16px;
        line-height: 110%;
        margin-bottom: 10px;
    }

    .resources-items .resources-item .text-section a {
        font-size: 15px;
        line-height: 120%;
        padding: 10px 20px;
    }

    .contact-section {
        padding: 29px 0 47px;
    }

    .contact-section.bg-color {
        padding: 44px 0 30px;
    }

    .contact-section.last-section {
        padding: 51px 0 57px;
    }

    .contact-section.error-section {
        padding: 51px 0 51px;
    }

    .contact-section h3 {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 20px;
    }

    .contact-section p {
        font-size: 16px;
        line-height: 110%;
        margin-bottom: 10px;
    }

    .contact-section .icon-text-container {
        /* display: block; */
        row-gap: 20px
    }

    .contact-section .icon-text-container .icon-text-item {
        margin-bottom: 0px;
    }

    .contact-section .icon-text-container .icon-text-item svg {
        width: 50px;
        height: 50px;
    }

    .contact-section .icon-text-container .icon-text-item h5 {
        font-size: 15px;
        line-height: 120%;
        margin-bottom: 1px;
    }

    .contact-section .icon-text-container .icon-text-item p {
        font-size: 15px;
        line-height: 120%;
        margin-bottom: 0;
    }

    /* welcome apage*/
    /* .welcome-banner-section{
        padding: 248px 0 150px;
        background: url(../images/welcome-bg-mobile.webp) no-repeat center center;
        background-size: cover;
        position: relative;
    } */
    .welcome-banner-section h1 {
        font-size: 24px;
        line-height: 110%;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
        margin-bottom: 10px;
    }

    .welcome-banner-section h2 {
        font-size: 20px;
        line-height: 110%;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
        max-width: 287px;
    }

    .welcome-banner-section .actor_portrayal_vector .actor_portrayal {
        font-size: 12px;
        line-height: 100%;
    }

    .tracking-your-progress-section {
        padding: 34px 0 38px;
    }

    .tracking-your-progress-section .container-copay {
        display: block;
    }

    .tracking-your-progress-section .left-section {
        max-width: 100%;
        margin-bottom: 63px;
    }

    .tracking-your-progress-section .left-section h2 {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 15px;
    }

    .tracking-your-progress-section .left-section p,
    .tracking-your-progress-section .left-section ul {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 110%;
    }

    .tracking-your-progress-section .left-section p:last-child {
        margin-bottom: 0;
    }

    .tracking-your-progress-section .right-section {
        padding: 40px 50px 20px;
        max-width: 337px;
        margin: auto;
    }

    .tracking-your-progress-section .right-section h3 {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 15px;
    }

    .download-resources-section {
        padding: 9px 0 27px;
    }

    .download-resources-section h4 {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 20px;
    }

    .download-resources-section p {
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 110%;
    }

    .download-resources-section .resources-items .resources-item {
        padding-top: 0;
        border: none;
    }

    /* walking assessment*/
    .ampyra-modal-walking-assessment .modal-content {
        padding: 0;
    }

    .ampyra-modal-walking-assessment .modal-content .close-button {
        top: 60px;
        right: 5px;
    }

    .ampyra-modal-walking-assessment.fix-close .modal-content .close-button {
        top: 8px !important;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps {
        padding: 25px 15px 25px;
        background: url(/public/assets/images/assessment-bg-mobile.webp) no-repeat top left;
        background-size: contain;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps h4 {
        font-size: 15px;
        line-height: 120%;
        margin-bottom: 60px;
        max-width: 180px;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .question-details h5 {
        font-size: 15px;
        line-height: 110%;
        text-align: left;
        margin-bottom: 20px;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options {
        flex-direction: column;
        gap: 17px;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options .question-option {
        max-width: 150px;
        margin: auto;
        text-align: left;
        width: 100%;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options input {
        margin-bottom: 0;
        display: inline-block;
        margin-right: 10px;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .question-options label {
        font-size: 15px;
        line-height: 26px;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container {
        flex-direction: row;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container button.show_desktop {
        display: none;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container button.show_mobile {
        padding: 0 !important;
        background: none;
        display: inline-block;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        border: none;

    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container button.show_mobile.previous {
        margin-left: 0;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container button.show_mobile.next {
        margin-right: 0;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container button.show_mobile:hover {
        background: none;
    }

    .ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container button.show_mobile:hover path,
    .ampyra-modal-walking-assessment .modal-content .assessment-steps .button_container button.show_mobile:hover rect {
        stroke: #335626;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps {
        padding: 40px 15px 30px;
        background: url(/public/assets/images/assessment-thank-you-bg-mobile.webp) no-repeat bottom center;
        background-size: cover;
        border-radius: 20px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps h4 {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 27px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps p {
        margin-bottom: 10px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps p b {
        font-size: 15px;
        line-height: 100%;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps p.some-witdth {
        font-size: 16px;
        line-height: 110%;
        margin-bottom: 27px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps .button_container_new {
        flex-direction: column;
        gap: 25px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps .button_container_new button {
        font-size: 15px;
        line-height: 120%;
        padding: 11px 40px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data {
        margin-top: 20px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data button {
        padding: 0;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data input {
        margin-left: 2px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps button.link {
        margin-top: 20px;
        font-size: 12px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data #sendEmailError {
        margin-left: 0;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps .thankyou-field-for-data h5 {
        max-width: 100%;
        font-size: 15px;
        line-height: 100%;
        margin-top: 20px;
    }


    /* homepage modal*/
    .ampyra-modal-leaving .modal-content,
    .ampyra-modal-email-access .modal-content {
        padding: 20px;
    }

    .ampyra-modal-leaving .modal-content .phone_number {
        margin: 20px auto;
    }

    .ampyra-modal-leaving .modal-content .phone_number a {
        font-size: 20px;
        line-height: 110%;
    }

    .ampyra-modal-leaving .modal-content p.notes {
        font-size: 12px;
        line-height: 100%;
    }

    .ampyra-modal .modal-content .modal-body ul {
        padding-left: 1.2em;
    }

    .ampyra-modal .modal-content .modal-body li {
        font-size: 16px;
        line-height: 110%;
    }

    .ampyra-modal .modal-content .modal-body p.notes {
        font-size: 12px;
        line-height: 100%;
    }

    .ampyra-modal .modal-content .modal-body p.notes a {
        text-decoration: underline;
    }

    .ampyra-modal.ampyra-modal-email-access .modal-content .button_container {
        flex-direction: row;
    }

    .ampyra-modal.ampyra-modal-email-access .modal-content .button_container .button {
        margin-bottom: 0 !important;
    }

    .ampyra-modal.ampyra-modal-email-access .modal-content .button_container .button.skip_button {
        margin-left: 0;
    }

    .ampyra-modal.ampyra-modal-email-access .modal-content .button_container .button.submit_button {
        margin-right: 0;
    }

    .inner-banner-section .actor_portrayal_vector .bottom_vector-position {
        position: relative;
        bottom: 0;
        top: 0;
    }

    .inner-banner-section .actor_portrayal_vector .bottom_vector-position {
        position: relative;
        bottom: 0;
        top: 30px;
    }

    .my-ampyra-provides-section .actor_portrayal_vector .actor_portrayal span {
        padding-right: 30px;
    }

    .coverage-copay-section .icon-text-section .icon-text-section-item img {
        max-width: 50px;
        max-height: 50px;
    }

    .coverage-copay-section .icon-text-section .icon-text-section-item {
        flex-wrap: nowrap;
        gap: 20px;
    }

    .ampyra-modal-walking-assessment .modal-content .other-steps .email-field-for-data .step7-email-field-input-button {
        text-align: -webkit-center;
    }

    .ampyra-modal-walking-assessment .modal-content .err-msg {
        text-align: -webkit-center;
        max-width: 100%;
        width: 100%;
        margin: auto;
        display: block;
        align-items: center;
        justify-content: center;
    }

    .ampyra-modal-walking-assessment .modal-content #sendEmailError {
        margin-left: 0px;
    }

    .coverage-copay-section .actor_portrayal_vector {
        margin-top: -18% !important;
    }

    .keep_a_journal {
        display: block;
    }

    #onetrust-banner-sdk #onetrust-close-btn-container {
        position: absolute !important;
        top: 10px !important;
        right: 10px !important;
    }

    #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
        margin-left: 0px !important;
    }
}

@media screen and (max-width:540px) {
    .resources-items .resources-item .img-section {
        max-width: 65px;
    }

    .resources-items .resources-item .text-section {
        max-width: calc(100% - 88px);
    }

    .coverage-copay-section .copay-assistance-available-section h4 {
        max-width: 225px;
        margin-left: auto;
        margin-right: auto;
    }

    .coverage-copay-section .call-us-section p {
        max-width: 216px;
        margin: auto;
    }

    .inner-banner-section .actor_portrayal_vector .bottom_vector-position {
        position: relative;
        bottom: 0;
        top: 0;
    }

    .welcome-banner-section img {
        /* position: relative; */
        max-height: 486px;
        height: 100%;
        width: 100%;
    }

    /* #onetrust-banner-sdk #onetrust-button-group {
        justify-content: flex-start !important;
        margin-left: 14px !important;
    }

    #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
        margin-left: 14px !important;
        margin-right: 14px !important;
    }
    #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
        width: 95%;
    } */

}

/* @media screen and (max-width:460px) and (min-width:360px) {
    #onetrust-banner-sdk #onetrust-accept-btn-handler {
        min-width: 180px !important;
        width: 100% !important;
    }

    #onetrust-banner-sdk #onetrust-pc-btn-handler {
        min-width: 180px !important;
        width: 100% !important;
    }
} */

@media screen and (max-width:460px) {
    .home-banner-section .intro-section h1 {
        max-width: 311px;
    }

    .home-banner-section .intro-section {
        margin-top: 0px;
    }

}

@media screen and (max-width:360px) {
    .home-banner-section .intro-section h1 {
        max-width: 220px;
    }

    .home-banner-section .intro-section h3 {
        max-width: 160px;
    }
}

@media screen and (max-width: 1024px) and (min-width:767px) {
    body #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
        max-width: calc(100% - 54px);
        width: 100%;
        float: none !important;
    }

    body #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group-parent {
        max-width: 100% !important;
        width: 100% !important;
    }

    body #onetrust-consent-sdk #onetrust-policy-text {
        text-align: left !important;
        width: 95% !important;
    }

    #onetrust-banner-sdk .ot-sdk-eight.ot-sdk-columns,
    #onetrust-pc-sdk .ot-sdk-eight.ot-sdk-columns,
    #ot-sdk-cookie-policy .ot-sdk-eight.ot-sdk-columns {
        width: 50% !important;
    }

    #onetrust-banner-sdk.vertical-align-content #onetrust-button-group-parent {
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translateY(-50%) !important;
        width: 43% !important;
    }

    #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group button {
        margin-bottom: 5px;
        margin-top: 5px;
        width: 100% !important;
    }

    #onetrust-banner-sdk #onetrust-button-group {
        justify-content: flex-start !important;
    }

    body #onetrust-banner-sdk {
        width: 100 !important;
        padding: 0;
    }

    #onetrust-banner-sdk #onetrust-accept-btn-handler {
        min-width: 175px !important;
    }

    #onetrust-banner-sdk #onetrust-button-group button#onetrust-accept-btn-handler {
        width: 4% !important;
    }
}

@media (max-width:767px) {
    body #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
        max-width: calc(100% - 54px);
        width: 100%;
        float: none !important;
        margin-left: 0 !important;
    }

    body #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group-parent {
        max-width: 100% !important;
        width: 100% !important;
        margin-left: 1em;
    }

    body #onetrust-consent-sdk #onetrust-policy-text {
        text-align: left !important;
        width: 95% !important;
    }

    #onetrust-banner-sdk .ot-sdk-row,
    #onetrust-pc-sdk .ot-sdk-row,
    #ot-sdk-cookie-policy .ot-sdk-row {
        flex-wrap: wrap !important;
    }

    #onetrust-banner-sdk #onetrust-button-group {
        justify-content: flex-start !important;
        /* margin-top: 10px !important;
    margin-bottom: 10px !important; */
    }

    body #onetrust-banner-sdk {
        width: 100 !important;
        padding: 0;
    }

    #onetrust-banner-sdk #onetrust-pc-btn-handler {
        padding-left: 0 !important;
        text-align: left !important;
    }

    #onetrust-banner-sdk #onetrust-pc-btn-handler,
    #onetrust-accept-btn-handler {
        max-width: calc(50% - 20px) !important;
        width: 100%;
    }

    #onetrust-banner-sdk #onetrust-accept-btn-handler {
        min-width: 175px !important;
    }
}

@media screen and (max-width:880) and (min-width:400px) {
    #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group button {
        margin-bottom: 5px;
        /* margin-top: 5px; */
        width: 100% !important;
        /* padding: 0 !important; */
        /* margin-left: -0.5em !important; */
    }


}

@media only screen and (max-width: 425px) {
    #onetrust-policy-text {
        font-size: 12px !important;
        line-height: 1.25em;
    }

    #onetrust-consent-sdk #onetrust-policy-text a {
        font-size: 12px !important;
        line-height: 1.25em;
    }

    #onetrust-banner-sdk #onetrust-policy {
        margin-top: 1.25em !important;
        margin-left: 0em !important;
    }

    body #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group-parent {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    #onetrust-banner-sdk .ot-sdk-container,
    #onetrust-pc-sdk .ot-sdk-container,
    #ot-sdk-cookie-policy .ot-sdk-container {
        padding-left: 1em !important;
    }
}

@media screen and (min-width:400px) {
    #onetrust-banner-sdk .ot-sdk-container {
        width: 100% !important;
        padding: 0;
    }
}