.App {

}


@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham'), url('../public/assets/font/Gotham-Bold.otf') format('woff');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 325;
  src: local('Gotham'), url('../public/assets/font/Gotham-Book.otf') format('woff');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.App-link {
  /* color: #61dafb; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
